

export type AttackedDevice = {
  NAS : "NAS",
  DATABASE_WITHOUT_VIRTUALIZATION: "Database without virtualization",
  ENDPOINT_DEVICE : "Endpoint Device",
  CLOUD_SYSTEM : "Cloud System",
  SERVER_VIRTUALIZATION : "Server Virtualization",
  OTHER : string,
}

export const AttackedDeviceConst = {
  NAS : "NAS",
  DATABASE_WITHOUT_VIRTUALIZATION: "Database without virtualization",
  ENDPOINT_DEVICE : "Endpoint Device",
  CLOUD_SYSTEM : "Cloud System",
  SERVER_VIRTUALIZATION : "Server Virtualization",
  OTHER : "lainya",
}

type SelectOption = {
  readonly value: string;
  readonly label: string;
}[]

export const AttackedDeviceOption = [
  {
    value: "NAS",
    label: "NAS",
  },
  {
    value: "DATABASE_WITHOUT_VIRTUALIZATION",
    label: "Database without virtualization",
  },
  {
    value: "ENDPOINT_DEVICE",
    label: "Endpoint Device",
  },
  {
    value: "CLOUD_SYSTEM",
    label: "Cloud System",
  },
  {
    value: "SERVER_VIRTUALIZATION",
    label: "Server Virtualization",
  },
  {
    value: "OTHER",
    label: "lainya",
  },
] as const satisfies Readonly<SelectOption>;

export const AttackedDeviceKey = [
  "NAS",
  "DATABASE_WITHOUT_VIRTUALIZATION",
  "ENDPOINT_DEVICE",
  "CLOUD_SYSTEM",
  "SERVER_VIRTUALIZATION",
  "OTHER",
]